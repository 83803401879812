/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import lime from '@material-ui/core/colors/lime';
import blue from '@material-ui/core/colors/blue';
import Refresh from '@material-ui/icons/Refresh';
import Wrapper from './HomeI18nWrapper';
import { getScoresWithPoints, pad } from '../matches/Utils';

function HomeAppMeMatch(props) {
    const { t, classes, match, scores, shooters, stages, registration, isStaff, history, onRefresh } = props;

    const { scoresByStage, scoresOverall, dqs } = useMemo(() => getScoresWithPoints({ scores, stages, shooters }), [scores, stages, shooters]);

    useEffect(() => {
        window.scrollTo(0, 0);

        document.body.classList.add(classes.body);
        document.documentElement.classList.add(classes.body);

        return () => {
            document.documentElement.classList.remove(classes.body);
            document.body.classList.remove(classes.body);
        };
    }, []);

    function refresh() {
        onRefresh();
    }

    function toMatchSite() {
        history.push(`/${match.alias}?mode=app}`);
    }

    function toMatchManagement() {
        history.push(`/${match.alias}/manage?mode=app`);
    }

    function toShooterStats() {
        history.push(`/${match.alias}/shooter-stats/shooter/${registration.id}?mode=app`);
    }

    function renderStageScore(stageIdx) {
        const stage = stages.find((s) => s.id.toString() === match.stages[stageIdx].toString());
        const userScoreIdx = scoresByStage[stageIdx].findIndex((s) => s.shooterId.toString() === ((registration || {}).id || '').toString());

        if (userScoreIdx === -1) {
            return (
                <div style={{ borderTop: stageIdx === 0 ? 'none' : `1px solid ${grey[300]}`, padding: '12px 0px' }}>
                    <div>
                        <Typography variant='h6'>{t('scorestage', { index: stageIdx + 1, title: stage.title })}</Typography>
                    </div>
                    <Typography style={{ color: grey[500], marginTop: '12px' }} variant='subtitle2'>
                        {t('no-result-for-stage')}
                    </Typography>
                </div>
            );
        }

        const userScore = scoresByStage[stageIdx][userScoreIdx];

        return (
            <div key={stageIdx} style={{ borderTop: stageIdx === 0 ? 'none' : `1px solid ${grey[300]}`, padding: '12px 0px' }}>
                <div>
                    <Typography variant='h6'>{t('scorestage', { index: stageIdx + 1, title: stage.title })}</Typography>
                </div>
                {[[
                    { title: 'A', value: userScore.alphas },
                    { title: 'C', value: userScore.charlies },
                    { title: 'D', value: userScore.deltas },
                    { title: 'M', value: userScore.misses },
                    { title: '(M)', value: userScore.bonusMisses },
                    { title: 'PT', value: userScore.noShoots },
                    { title: 'Proc', value: userScore.penalties },
                    { title: 'Ded', value: userScore.specialPenaltyPercentageStr },
                ], [
                    { title: t('generic:result_time'), value: userScore.timeStr },
                    { title: t('generic:result_total'), value: userScore.total },
                    { title: t('generic:result_factor'), value: userScore.factorStr },
                ], [
                    { title: t('result-%'), value: `${pad(parseInt((userScore.factor * 10000) / scoresByStage[stageIdx][0].factor, 10) / 100, 2)}%` },
                    { title: t('generic:result_stage_points'), value: userScore.points },
                    { title: t('result-pos'), value: `${userScoreIdx + 1} / ${scoresByStage[stageIdx].length}` },
                ]].map((line, lineIdx) => (
                    <div key={lineIdx}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {line.filter((a) => !!a.value).map((a, i) => ([
                                (i > 0) ? (
                                    <Typography variant='body2' style={{ fontWeight: 100, display: 'inline-block' }}>
                                        &nbsp;
                                        |
                                        &nbsp;
                                    </Typography>
                                ) : null,
                                <div key={a.title} style={{ marginTop: '12px' }}>
                                    <div>
                                        <Typography variant='body2' style={{ fontWeight: 800, display: 'inline-block' }}>
                                            {a.value}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography variant='caption' style={{ fontWeight: 100, display: 'inline-block' }}>
                                            {a.title}
                                        </Typography>
                                    </div>
                                </div>,
                            ]))}
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    function renderDQ(dq) {
        return (
            <Card style={{ marginTop: '12px' }}>
                <CardContent>
                    <div>
                        <Typography variant='h6' style={{ color: red[500] }}>{t('result-dq')}</Typography>
                    </div>
                    {[[
                        { title: t('dq-rule'), value: dq.dq },
                        { title: t('dq-date'), value: new Date(dq.timestamp).toLocaleString() },
                    ]].map((line, lineIdx) => (
                        <div key={lineIdx}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {line.filter((a) => !!a.value).map((a, i) => (
                                    <div key={a.title} style={{ marginTop: '12px' }}>
                                        {(i > 0) && (
                                            <Typography variant='body2' style={{ fontWeight: 100, display: 'inline-block' }}>
                                                &nbsp;
                                                |
                                                &nbsp;
                                            </Typography>
                                        )}
                                        <Typography variant='body2' style={{ fontWeight: 100, display: 'inline-block' }}>
                                            {a.title}
                                        </Typography>
                                        <Typography variant='body2' style={{ fontWeight: 800, display: 'inline-block' }}>
                                            {a.value}
                                        </Typography>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </CardContent>
            </Card>
        );
    }

    function renderResult() {
        let result = <Typography variant='h6'>{t('registration')}</Typography>;
        let avatar = null;

        if (registration) {
            const scoresDivision = scoresOverall.filter((s) => s.division === registration.division);
            const meIndex = scoresDivision.findIndex((s) => s.shooterId === registration.id);
            if (meIndex > -1) {
                const me = scoresDivision[meIndex];
                const scoresCategory = scoresDivision.filter((s) => s.category === registration.category);
                const meCatIndex = registration.category === 'regular' ? -1 : scoresCategory.findIndex((s) => s.shooterId === registration.id);

                result = (() => {
                    if (registration.category !== 'regular') {
                        return (
                            <Typography variant='h6'>
                                {t('result-category', {
                                    pos: t((meIndex + 1).toString()),
                                    all: scoresDivision.length,
                                    points: pad(me.score, 4),
                                    percentage: parseInt(me.percentage * 10000, 10) / 100,
                                    catPos: t((meCatIndex + 1).toString()),
                                    catPercentage: parseInt((me.percentage / scoresCategory[0].percentage) * 10000, 10) / 100,
                                })}
                            </Typography>
                        );
                    }

                    return (
                        <Typography variant='h6'>
                            {t('result', {
                                pos: t((meIndex + 1).toString()),
                                all: scoresDivision.length,
                                points: pad(me.score, 4),
                                percentage: pad(me.percentage / 100, 2),
                            })}
                        </Typography>
                    );
                })();

                avatar = (() => {
                    if ((meIndex === 0) || (meCatIndex === 0)) {
                        return <i style={{ color: yellow[600], fontSize: '36px', marginBottom: '12px' }} className='fas fa-trophy' />;
                    }
                    if ((meIndex === 1) || (meCatIndex === 1)) {
                        return <i style={{ color: grey[500], fontSize: '36px', marginBottom: '12px' }} className='fas fa-medal' />;
                    }
                    if ((meIndex === 2) || (meCatIndex === 2)) {
                        return <i style={{ color: lime[900], fontSize: '36px', marginBottom: '12px' }} className='fas fa-medal' />;
                    }
                    return null;
                })();
            }
        }

        return (
            <Card style={{ margin: 'auto', marginTop: '12px', width: 'calc(100% - 12px)' }}>
                <CardContent>
                    {avatar}
                    {result}
                    {registration && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '12px',
                            }}
                        >
                            <div style={{ flex: 1 }}>
                                <div>
                                    <Typography variant='body2' style={{ fontWeight: 800, display: 'inline-block' }}>
                                        {t(`generic:division-${registration.division}`)}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant='caption' style={{ fontWeight: 100, display: 'inline-block' }}>
                                        {t('generic:shooter_division')}
                                    </Typography>
                                </div>
                            </div>
                            <Typography variant='body2' style={{ fontWeight: 100, display: 'inline-block' }}>
                                &nbsp;
                                |
                                &nbsp;
                            </Typography>
                            <div style={{ flex: 1 }}>
                                <div>
                                    <Typography variant='body2' style={{ fontWeight: 800, display: 'inline-block' }}>
                                        {t(`registration:category-${registration.category}`)}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant='caption' style={{ fontWeight: 100, display: 'inline-block' }}>
                                        {t('generic:shooter_category')}
                                    </Typography>
                                </div>
                            </div>
                            <Typography variant='body2' style={{ fontWeight: 100, display: 'inline-block' }}>
                                &nbsp;
                                |
                                &nbsp;
                            </Typography>
                            <div style={{ flex: 1 }}>
                                <div>
                                    <Typography variant='body2' style={{ fontWeight: 800, display: 'inline-block' }}>
                                        {t(`registration:${registration['power-factor']}`)}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant='caption' style={{ fontWeight: 100, display: 'inline-block' }}>
                                        {t('generic:shooter_power_factor')}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    )}
                </CardContent>
            </Card>
        );
    }

    const rounds = _.reduce(stages, (sum, s) => sum + (s.rounds || 0), 0);
    const dq = dqs.find((s) => s.shooterId === (registration || {}).id);

    return (
        <div className={classes.wrapper}>
            <div className={classes.topbar}>
                <div style={{ flex: 1 }}>
                    <Button variant='text' color='primary' style={{ minWidth: '0px' }} onClick={() => history.goBack()}>
                        <Typography variant='body2' style={{ color: grey[500] }}>
                            {t('me')}
                        </Typography>
                    </Button>
                    <i className='fas fa-chevron-right' style={{ fontSize: '12px', opacity: 0.5 }} />
                    <Button variant='text' color='primary' disabled>
                        <Typography variant='body2' style={{ color: blue[500] }}>
                            {t('match')}
                        </Typography>
                    </Button>
                </div>
                <IconButton color='primary' onClick={refresh}><Refresh /></IconButton>
            </div>

            <Card classes={{ root: classes.header }} style={{ backgroundImage: `url(${match.cover})` }}>
                <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ background: 'rgba(0, 0, 0, 0.3)', padding: '24px', paddingTop: '12px' }}>
                        <Typography variant='h5' style={{ paddingTop: '12px', color: grey[300] }}>
                            {match.title}
                        </Typography>
                        <Typography variant='subtitle2' style={{ paddingTop: '6px', color: grey[400] }}>
                            {`${match.stages.length} ${t('matchhome:shooters-stages-title')} | ${rounds} ${t('matchhome:rounds')} | ${shooters.length} ${t('matchhome:shooters')}`}
                        </Typography>
                    </div>
                </CardContent>
            </Card>
            {!registration ? null : (
                <div>
                    <Typography
                        variant='body2'
                        style={{
                            color: grey[500],
                            margin: '0px 12px',
                            marginTop: '12px',
                            textAlign: 'start',
                        }}
                    >
                        {t('yourresult')}
                    </Typography>
                    {dq ? renderDQ(dq) : renderResult()}
                </div>
            )}
            {dq || !registration ? null : (
                <div>
                    <Typography
                        variant='body2'
                        style={{
                            color: grey[500],
                            margin: '0px 12px',
                            marginTop: '12px',
                            textAlign: 'start',
                        }}
                    >
                        {t('yourscores')}
                    </Typography>
                    <Card style={{ margin: 'auto', marginTop: '12px', width: 'calc(100% - 12px)' }}>
                        {match.stages.map((stage, idx) => renderStageScore(idx))}
                    </Card>
                </div>
            )}
            <Typography
                variant='body2'
                style={{
                    color: grey[500],
                    margin: '0px 12px',
                    marginTop: '12px',
                    textAlign: 'start',
                }}
            >
                {t('links')}
            </Typography>
            <Card style={{ margin: 'auto', marginTop: '12px', width: 'calc(100% - 12px)' }}>
                <ButtonBase classes={{ root: classes.matchitem }} onClick={toMatchSite}>
                    <div style={{ flex: 1 }}>
                        <Typography variant='subtitle2'>
                            {t('tosite')}
                        </Typography>
                        <Typography variant='caption'>
                            {t('tositedescription')}
                        </Typography>
                    </div>
                    <div>
                        <i className='fas fa-chevron-right' style={{ fontSize: '18px' }} />
                    </div>
                </ButtonBase>
                {isStaff && (
                    <ButtonBase classes={{ root: classes.matchitem }} onClick={toMatchManagement}>
                        <div style={{ flex: 1 }}>
                            <Typography variant='subtitle2'>
                                {t('tomanage')}
                            </Typography>
                            <Typography variant='caption'>
                                {t('tomanagedescription')}
                            </Typography>
                        </div>
                        <div>
                            <i className='fas fa-chevron-right' style={{ fontSize: '18px' }} />
                        </div>
                    </ButtonBase>
                )}
                <ButtonBase classes={{ root: classes.matchitem }} onClick={toShooterStats}>
                    <div style={{ flex: 1 }}>
                        <Typography variant='subtitle2'>
                            {t('tostats')}
                        </Typography>
                        <Typography variant='caption'>
                            {t('tostatsdescription')}
                        </Typography>
                    </div>
                    <div>
                        <i className='fas fa-chevron-right' style={{ fontSize: '18px' }} />
                    </div>
                </ButtonBase>
            </Card>
        </div>
    );
}

const styles = {
    body: {
        background: grey[200],
    },
    topbar: {
        width: '100%',
        height: '45px',
        background: 'white',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'start',
    },
    wrapper: {
        background: grey[200],
        textAlign: 'center',
        paddingBottom: '70px',
    },
    header: {
        position: 'relative',
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    matchitem: {
        padding: '12px',
        textAlign: 'start',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        borderTop: `1px solid ${grey[300]}`,
        '&:nth-child(1)': {
            borderTop: 'none',
        },
    },
    media: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: 'calc(100vw - 12px)',
        marginTop: '12px',
        paddingLeft: '6px',
        paddingRight: '6px',
        overflowX: 'auto',
    },
    mediabox: {
        display: 'flex',
        width: '200px',
        height: '200px',
        background: 'white',
        marginRight: '12px',
        position: 'relative',
        minWidth: '200px',
        minHeight: '200px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
    },
    newmediabox: {
        display: 'flex',
        flexDirection: 'column',
        width: '200px',
        height: '200px',
        background: 'white',
        minWidth: '200px',
        '&>button': {
            flex: 1,
            '&>span': {
                '&>div': {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    '&>i': {
                        fontSize: '30px',
                    },
                },
            },
        },
    },
};

HomeAppMeMatch.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    match: PropTypes.shape({}).isRequired,
    scores: PropTypes.array,
    shooters: PropTypes.array,
    stages: PropTypes.array,
    registration: PropTypes.shape({}),
    isStaff: PropTypes.bool,
};

HomeAppMeMatch.defaultProps = {
    scores: null,
    shooters: null,
    stages: null,
    registration: null,
    isStaff: false,
};

export default Wrapper(withStyles(styles, { withTheme: true, flip: false })(withTranslation('homeappuserdashboard')(HomeAppMeMatch)));