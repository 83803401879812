// React imports
import React, { useEffect, useState } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Generic imports
import PropTypes from 'prop-types';
import _ from 'lodash';

// Material UI imports
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Refresh from '@material-ui/icons/Refresh';
import blue from '@material-ui/core/colors/blue';
import CircularProgress from '@material-ui/core/CircularProgress';

// Internal imports
import api from '../Api';
import Profile from './Profile';
import HomeAppMeMatch from './HomeApp.me.match';

function HomeAppMeUser(props) {
    const { history, t, classes, publicId } = props;

    const [registrations, setRegistrations] = useState(null);
    const [staff, setStaff] = useState(null);
    const [matches, setMatches] = useState(null);
    const [stages, setStages] = useState(null);
    const [shooters, setShooters] = useState(null);
    const [scores, setScores] = useState(null);

    async function refresh() {
        setRegistrations(null);
        setStaff(null);
        setMatches(null);
        setStages(null);
        setShooters(null);
        setScores(null);

        const { registrations: $registrations, staff: $staff } = await api.getMatches({ mode: 'as-shooter-or-staff', publicId});
        setRegistrations($registrations);
        setStaff($staff);

        const matchesIds = _.uniq([
            ...$staff,
            ...$registrations.map((r) => r.matchId),
        ]);
        const $matches = await Promise.all(matchesIds.map((m) => api.getMatch({ matchId: m })));
        $matches.sort((a, b) => ((new Date(b.startDate.year, b.startDate.month - 1, b.startDate.day).getTime()) - (new Date(a.startDate.year, a.startDate.month - 1, a.startDate.day).getTime())));
        setMatches($matches);

        const [ allStages, allScores, allShooters ] = await Promise.all([
            api.getStages({ stages: _.compact(_.flatten($matches.map((m) => m.stages))) }),
            Promise.all($matches.map((m) => (m.scoresPublished ? api.getScores({ matchId: m.id }) : Promise.resolve([])))),
            Promise.all($matches.map((m) => (m.scoresPublished ? api.getShooters({ matchId: m.id }) : Promise.resolve([])))),
        ]);

        setStages(allStages);
        setScores(allScores);
        setShooters(allShooters);
    }

    useEffect(() => {
        if (!publicId || publicId.indexOf('|') === -1 || registrations) return;

        refresh();
    }, [ registrations, publicId ]);

    if (!registrations) {
        return (
            <div className={classes.loadingwrapper}>
                <CircularProgress thickness={1} size={72} />
                <Typography variant='caption' style={{ color: blue[500] }}>
                    {t('step1')}
                </Typography>
            </div>
        );
    }

    if (!matches) {
        return (
            <div className={classes.loadingwrapper}>
                <CircularProgress thickness={1} size={72} />
                <Typography variant='caption' style={{ color: blue[500] }}>
                    {t('step2')}
                </Typography>
            </div>
        );
    }

    if (!stages || !shooters || !scores) {
        return (
            <div className={classes.loadingwrapper}>
                <CircularProgress thickness={1} size={72} />
                <Typography variant='caption' style={{ color: blue[500] }}>
                    {t('step3')}
                </Typography>
            </div>
        );
    }

    return (
        <div>
            <Route
                path={['/app/1/:matchId', '/app/1/']}
                render={($props) => (
                    <HomeAppMeUserInner
                        registrations={registrations}
                        setRegistrations={setRegistrations}
                        staff={staff}
                        setStaff={setStaff}
                        matches={matches}
                        setMatches={setMatches}
                        stages={stages}
                        setStages={setStages}
                        shooters={shooters}
                        setShooters={setShooters}
                        scores={scores}
                        setScores={setScores}
                        onMatchClick={(m) => history.push(`/app/1/${m}${window.location.search}`)}
                        onRefresh={refresh}
                        {...props}
                        {...$props}
                    />
                )}
            />
        </div>
    );
}

HomeAppMeUser.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
};

const userstyles = () => ({
    loadingwrapper: {
        width: '100vw',
        height: 'calc(100vh - 56px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    topbar: {
        width: '100%',
        height: '45px',
        background: 'white',
        display: 'flex',
        alignItems: 'center',
    },
    loggedinwrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        paddingBottom: '56px',
    },
});

export default withStyles(userstyles, { withTheme: true, flip: false })(withTranslation('homeappme')(HomeAppMeUser));

function HomeAppMeUserInner(props) {
    const {
        history, t, classes, match: routeMatch, onLogout, setRegistrations, matches, 
        registrations, scores, staff, shooters, stages, onRefresh,
    } = props;

    if (routeMatch.params.matchId) {
        const matchId = routeMatch.params.matchId;
        const matchIndex = matches.findIndex((m) => m.id === matchId);
        const match = matches[matchIndex];
        const $scores = scores[matchIndex];
        const $shooters = shooters[matchIndex];
        const $stages = match.stages.map((s) => stages.find((stage) => stage.id === s));
        const isStaff = staff.includes(matchId);
        const registration = registrations.find((r) => r.matchId === matchId);

        return (
            <HomeAppMeMatch
                history={history}
                onRefresh={onRefresh}
                match={match}
                registration={registration}
                scores={$scores}
                shooters={$shooters}
                stages={$stages}
                isStaff={isStaff} 
            />
        );
    }

    return (
        <div className={classes.loggedinwrapper}>
            <div className={classes.topbar}>
                <div style={{ flex: 1 }}>
                    <Button variant='text' color='primary' disabled>
                        <Typography variant='body2' style={{ color: blue[500] }}>
                            {t('me')}
                        </Typography>
                    </Button>
                </div>
                <IconButton color='primary' onClick={() => setRegistrations(null)}><Refresh /></IconButton>
                <IconButton color='secondary' onClick={onLogout}>
                    <i className='fas fa-sign-out-alt' />
                </IconButton>
            </div>
            <Profile {..._.omit(props, ['classes'])} />
        </div>
    );
}

HomeAppMeUserInner.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    accessToken: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    mode: PropTypes.string,
    setRegistrations: PropTypes.func.isRequired,
    setStages: PropTypes.func.isRequired,
    setMatches: PropTypes.func.isRequired,
    setStaff: PropTypes.func.isRequired,
    setShooters: PropTypes.func.isRequired,
    setScores: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    staff: PropTypes.arrayOf(PropTypes.string),
    registrations: PropTypes.arrayOf(PropTypes.shape({})),
    matches: PropTypes.arrayOf(PropTypes.shape({})),
    scores: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
    stages: PropTypes.arrayOf(PropTypes.shape({})),
    shooters: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
};

HomeAppMeUserInner.defaultProps = {
    mode: null,
    staff: null,
    registrations: null,
    matches: null,
    scores: null,
    stages: null,
    shooters: null,
};
