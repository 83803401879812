import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import XLSX from 'xlsx';
import uuidv4 from 'uuid/v4';
import validator from 'validator';
import saveAs from 'file-saver';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { getFlagSrc, getAllDivisions, getAllCategories } from './Utils';
import api from '../Api';

class ShootersListImport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            shooters: null,
            loading: false,
            exporting: false,
            sortBy: 'id',
            sortAscDesc: 'asc',
            exportCancelled: false,
        };
    }

    componentDidMount() {
        const {
            setTitle, setSaveButtonCallback, setExtraButtons, setBackButtonVisible, t, match,
        } = this.props;
        setTitle(t('title', { name: match.title }));

        setSaveButtonCallback(null);
        setExtraButtons([]);
        setBackButtonVisible(true);
    }

    onFile = async (e) => {
        const {
            t, match, auth,
        } = this.props;
        const { files } = e.target;
        const f = files[0];

        await new Promise((res) => this.setState({ loading: true }, res));

        try {
            const data = await new Promise((res) => {
                const reader = new FileReader();
                reader.onload = (e2) => {
                    res(e2.target.result);
                };
                reader.readAsBinaryString(f);
            });

            const workbook = XLSX.read(data, { type: 'binary' });
            const sheet = workbook.Sheets[workbook.SheetNames[0]];
            const json = XLSX.utils.sheet_to_json(sheet, { header: ['publicId', 'name', 'club', 'team', 'ics', 'email', 'region', 'division', 'category', 'power-factor', 'paid', 'squad'] });
            json.shift(); // Disregard first row

            const existingShooters = await api.getShooters({ matchId: match.id, auth });

            const shooters = json.map((s) => {
                const publicId = (() => {
                    if (!s.publicId) return null;
                    if (s.publicId.toString().indexOf('|') > -1) return s.publicId.toString();
                    if ((s.region) && (s.region.length === 2)) return `${s.region.toUpperCase()}|${s.publicId}`;
                    return `${match.region.toUpperCase()}|${s.publicId}`;
                })();
                const ret = {
                    publicId: {
                        error: !publicId ? t('error-no-id') : null,
                        value: `${publicId}`,
                        text: (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {(publicId.indexOf('|') > -1) && (
                                    <img
                                        alt={publicId.split('|')[0]}
                                        src={getFlagSrc({ countryCode: publicId.split('|')[0], size: 16 })}
                                    />
                                )}
                                <div style={{ padding: '0px 6px' }}>
                                    {publicId.split('|').pop()}
                                </div>
                            </div>
                        ),
                    },
                    name: {
                        error: !s.name ? t('error-no-name') : null,
                        value: s.name,
                        text: s.name,
                    },
                    club: {
                        error: !s.club && !!match.registrationLimitGShee ? t('error-no-club') : null,
                        value: s.club ? s.club : null,
                        text: s.club,
                    },
                    email: (() => {
                        if ((s.email) && (!validator.isEmail(s.email))) {
                            return { error: t('error-bad-email', { email: s.email }) };
                        }
                        return { value: s.email || '', text: s.email || '' };
                    })(),
                    division: (() => {
                        if (!s.division) {
                            return { error: t('error-no-division') };
                        }

                        const d = getAllDivisions({ matchType: match.type }).find((div) => {
                            if (div === s.division.replace(/\s/g, '-').toLowerCase()) return true;
                            if (t(`generic:division-${div}`) === s.division) return true;
                            return false;
                        });

                        if (d) {
                            return { value: d, text: t(`generic:division-${d}`) };
                        }

                        return { error: t('error-bad-division', { division: s.division }) };
                    })(),
                    category: (() => {
                        if (!s.category) {
                            return { value: 'regular', text: t('registration:category-regular') };
                        }
                        const c = getAllCategories({ matchType: match.type }).find((cat) => {
                            if (cat === s.category.replace(/\s/g, '-').toLowerCase()) return true;
                            if (t(`registration:category-${cat}`) === s.category) return true;
                            if (t(`generic:category-${cat}`) === s.category) return true;
                            return false;
                        });

                        if (c) {
                            return { value: c, text: t(`registration:category-${c}`) };
                        }

                        return { error: t('error-bad-category', { category: s.category }) };
                    })(),
                    'power-factor': (() => {
                        if (!s['power-factor']) {
                            return { value: 'minor', text: t('registration:minor') };
                        }
                        if (s['power-factor'].toLowerCase() === 'minor') {
                            return { value: 'minor', text: t('registration:minor') };
                        }
                        if (s['power-factor'].toLowerCase() === 'major') {
                            return { value: 'major', text: t('registration:major') };
                        }
                        if (s['power-factor'].toLowerCase() === 'min') {
                            return { value: 'minor', text: t('registration:minor') };
                        }
                        if (s['power-factor'].toLowerCase() === 'maj') {
                            return { value: 'major', text: t('registration:major') };
                        }
                        if (s['power-factor'].toLowerCase() === t('registration:minor')) {
                            return { value: 'minor', text: t('registration:minor') };
                        }

                        if (s['power-factor'].toLowerCase() === t('registration:major')) {
                            return { value: 'major', text: t('registration:major') };
                        }

                        return { error: t('error-bad-power-factor', { powerfactor: s['power-factor'] }) };
                    })(),
                    paid: (() => {
                        if (!s.paid) {
                            return { value: false, text: '' };
                        }
                        if (s.paid.toLowerCase() === 'no') {
                            return { value: false, text: '' };
                        }
                        if (s.paid.toLowerCase() === 'yes') {
                            return { value: true, text: '✓' };
                        }
                        if (s.paid.toLowerCase() === '✓') {
                            return { value: true, text: '✓' };
                        }
                        if (s.paid.toLowerCase() === t('generic:no').toLowerCase()) {
                            return { value: false, text: '' };
                        }
                        if (s.paid.toLowerCase() === t('generic:yes').toLowerCase()) {
                            return { value: true, text: '✓' };
                        }
                        return { error: t('error-bad-paid', { paid: s.paid }) };
                    })(),
                    squad: (() => {
                        if (!s.squad) {
                            return { text: '' };
                        }
                        if ((match.squads || []).find((sq) => sq.title.toString() === s.squad.toString())) {
                            return { value: match.squads.find((sq) => sq.title.toString() === s.squad.toString()).id, text: s.squad };
                        }
                        return { error: t('error-bad-squad', { squad: s.squad }) };
                    })(),
                    team: (() => {
                        if (!s.team) {
                            return { text: '' };
                        }
                        if ((match.teams || []).find((team) => team.title.toString() === s.team.toString())) {
                            return { value: match.teams.find((team) => team.title.toString() === s.team.toString()).id, text: s.team };
                        }
                        return { error: t('error-bad-team', { team: s.team }) };
                    })(),
                    ics: (() => {
                        if (!s.ics) {
                            return { value: null, text: t('generic:ics-unclassified') };
                        }
                        const c = ['grand-master', 'master', 'a', 'b', 'c', 'd'].find((ics) => {
                            if (t(`generic:ics-${ics}`) === s.ics) return true;
                            return false;
                        });

                        if (c) {
                            return { value: c, text: t(`generic:ics-${c}`) };
                        }

                        return { error: t('error-bad-ics', { ics: s.ics }) };
                    })(),
                };

                const error = _.some(ret, (v) => !!v.error);
                ret.error = error;

                const existing = existingShooters.find((es) => publicId.toString() === es.publicId.toString());

                ret.existing = !!existing;
                ret.id = existing ? existing.id : uuidv4();
                if (existing && ret.club.value === null) ret.club.value = undefined;
                ret.selected = !error;

                const s3 = { id: ret.id };
                if (existing && existing.chronos) s3.chronos = existing.chronos;
                if (existing && existing.equipmentChecks) s3.equipmentChecks = existing.equipmentChecks;
                if (existing && existing.log) s3.log = existing.log;
                if (existing && existing.extraFields) s3.extraFields = existing.extraFields;
                if (existing && existing.labels) s3.labels = existing.labels;
                if (existing && existing.created) s3.created = existing.created;
                if (existing) s3.verified = existing.verified;
                Object.keys(ret).forEach((k) => { if (_.has(ret[k], 'value')) { s3[k] = ret[k].value; } });
                if (_.isEqual(existing, s3)) {
                    ret.noChange = true;
                    ret.importingSuccessful = true;
                }
                return ret;
            });

            this.setState({ shooters, loading: false });
        } catch (e2) {
            console.error(e2);
            this.setState({ shooters: null, loading: false });
        }
    }

    sortBy = (field) => {
        const { sortBy, sortAscDesc } = this.state;
        const ascDesc = ((field === sortBy) && (sortAscDesc === 'asc')) ? 'desc' : 'asc';
        this.setState({ sortBy: field, sortAscDesc: ascDesc });
    }

    onSelectClick = (id) => {
        const { shooters } = this.state;
        const shootersNew = shooters.map((s) => (s.id === id ? { ...s, selected: !s.selected } : s));
        this.setState({ shooters: shootersNew });
    }

    onSelectAllClick = () => {
        const { shooters } = this.state;
        const allSelected = _.every(shooters, (s) => s.error || s.importingSuccessful || s.importingError || s.selected);
        const shootersNew = shooters.map((s) => ({ ...s, selected: s.error || s.importingSuccessful || s.importingError ? false : !allSelected }));
        this.setState({ shooters: shootersNew });
    }

    onImport = async () => {
        const { match, auth } = this.props;
        let { shooters } = this.state;
        shooters = shooters.map((s) => ({ ...s, importing: !s.error && !s.importingSuccessful && !s.importingError && s.selected }));
        await new Promise((res) => this.setState({ shooters }, res));
        shooters = JSON.parse(JSON.stringify(shooters));

        let p = Promise.resolve();
        while (shooters.length > 0) {
            const block = shooters.splice(0, 10);
            p = p.then(async () => {
                await Promise.all(block.map(async (s) => {
                    if (!s.importing) return;
                    const shooter = { id: s.id };
                    Object.keys(s).forEach((k) => { if (_.has(s[k], 'value')) { shooter[k] = s[k].value; } });
                    try {
                        if (s.existing) {
                            await api.patchShooter({
                                matchId: match.id,
                                shooterId: s.id,
                                auth,
                                shooter: _.omit(shooter, ['id', 'paid', 'club']),
                            });
                        } else {
                            await api.postShooter({
                                matchId: match.id,
                                auth,
                                shooter,
                            });
                        }
                        let { shooters: stateShooters } = this.state;
                        stateShooters = stateShooters.map((_s) => (_s.id !== s.id ? _s : ({
                            ..._s, importing: false, selected: false, importingSuccessful: true,
                        })));
                        await new Promise((res) => this.setState({ shooters: stateShooters }, res));
                    } catch (e) {
                        let { shooters: stateShooters } = this.state;
                        stateShooters = stateShooters.map((_s) => (_s.id !== s.id ? _s : ({
                            ..._s, importing: false, selected: false, importingError: true,
                        })));
                        await new Promise((res) => this.setState({ shooters: stateShooters }, res));
                    }
                }));
            });
        }
        await p;
    }

    onExport = async () => {
        const {
            t, match, auth,
        } = this.props;
        const { exportCancelled } = this.state;

        await new Promise((res) => this.setState({ exporting: true }, res));

        const shooters = await api.getShooters({ matchId: match.id, auth }) || [];
        const showPayment = _.some(shooters, (s) => typeof (s.paid) !== 'undefined');

        let rows = [];

        rows.push([
            ...[t('generic:shooter_id'), t('generic:shooter_name'), t('generic:shooter_club'), t('generic:shooter_team'), t('generic:shooter_ics'), t('generic:shooter_email'), t('generic:shooter_region')],
            ...[t('generic:shooter_division'), t('generic:shooter_category'), t('generic:shooter_power_factor')],
            ...(showPayment ? [t('registration:paid')] : []),
            ...[t('generic:squad_number')],
            ...match.registrationExtraFields.map((field) => t(`generic:${field.split('_optional')[0]}`)),
        ]);

        rows = [...rows, ...(shooters.filter((s) => exportCancelled || !s.cancelledParticipation).map((s) => ([
            ...[s.publicId.split('|')[1], s.name, s.club],
            ...[s.team ? match.teams.find((team) => team.id === s.team).title : ''],
            ...[s.ics ? t(`generic:ics-${s.ics}`) : ''],
            ...[s.email, s.publicId.split('|')[0]],
            ...[t(`generic:division-${s.division}`), t(`generic:category-${s.category}`), t(`generic:power-factor-${s['power-factor']}`)],
            ...(showPayment ? [s.paid ? '✓' : ''] : []),
            ...[s.cancelledParticipation ? t('cancelled-participation') : s.squad ? match.squads.find((sq) => sq.id === s.squad).title : ''],
            ...match.registrationExtraFields.map((field) => (s.extraFields || {})[field] || ''),
        ])))];

        const ws = XLSX.utils.aoa_to_sheet(rows);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const wopts = { bookType: 'xlsx', bookSST: false, type: 'array' };
        const wbout = XLSX.write(wb, wopts);
        saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${match.title}.xlsx`);

        await new Promise((res) => this.setState({ exporting: false }, res));
    }

    renderTable() {
        const { t, classes } = this.props;
        const { shooters, sortBy, sortAscDesc } = this.state;
        const allNotSelected = _.every(shooters, (s) => s.error || s.importingSuccessful || s.importingError || !s.selected);
        const allSelected = _.every(shooters, (s) => s.error || s.importingSuccessful || s.importingError || s.selected);
        const importing = _.some(shooters, (s) => s.importing);

        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button color='primary' variant='contained' style={{ margin: '0px 12px' }} disabled={allNotSelected || importing} onClick={this.onImport}>{t('importselected')}</Button>
                    <Button color='secondary' variant='contained' disabled={importing} onClick={() => this.setState({ shooters: null })}>{t('reset')}</Button>
                </div>
                <div style={{ width: '100%', overflowX: 'auto' }}>
                    <Table style={{ marginBottom: '24px' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell padding='checkbox'>
                                    <Checkbox
                                        indeterminate={!allSelected && !allNotSelected}
                                        checked={allSelected}
                                        onChange={this.onSelectAllClick}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel active={sortBy === 'existing'} direction={sortAscDesc} onClick={() => this.sortBy('existing')}>
                                        {t('updatenew')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel active={sortBy === 'id'} direction={sortAscDesc} onClick={() => this.sortBy('id')}>
                                        {t('generic:shooter_id')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel active={sortBy === 'name'} direction={sortAscDesc} onClick={() => this.sortBy('name')}>
                                        {t('generic:shooter_name')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel active={sortBy === 'club'} direction={sortAscDesc} onClick={() => this.sortBy('club')}>
                                        {t('generic:shooter_club')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel active={sortBy === 'team'} direction={sortAscDesc} onClick={() => this.sortBy('team')}>
                                        {t('generic:shooter_team')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel active={sortBy === 'ics'} direction={sortAscDesc} onClick={() => this.sortBy('ics')}>
                                        {t('generic:shooter_ics')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel active={sortBy === 'email'} direction={sortAscDesc} onClick={() => this.sortBy('email')}>
                                        {t('generic:shooter_email')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel active={sortBy === 'division'} direction={sortAscDesc} onClick={() => this.sortBy('division')}>
                                        {t('generic:shooter_division')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel active={sortBy === 'category'} direction={sortAscDesc} onClick={() => this.sortBy('category')}>
                                        {t('generic:shooter_category')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel active={sortBy === 'power-factor'} direction={sortAscDesc} onClick={() => this.sortBy('power-factor')}>
                                        {t('generic:shooter_power_factor')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel active={sortBy === 'paid'} direction={sortAscDesc} onClick={() => this.sortBy('paid')}>
                                        {t('registration:paid')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel active={sortBy === 'squad'} direction={sortAscDesc} onClick={() => this.sortBy('squad')}>
                                        {t('generic:squad_number')}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.orderBy(shooters, (s) => {
                                if (sortBy === 'existing') {
                                    return [s.error, s.existing, s.name.error || s.name.text];
                                } if (sortBy === 'squad') {
                                    return [s.error, s.squad.error || s.squad.text, s.name.error || s.name.text];
                                } if (sortBy === 'team') {
                                    return [s.error, s.team.error || s.team.text, s.name.error || s.name.text];
                                } if (sortBy === 'ics') {
                                    return [s.error, s.ics.error || s.ics.text, s.division.error || s.division.text, s.name.error || s.name.text];
                                } if (sortBy === 'division') {
                                    return [s.error, s.division.error || s.division.text, s.category.error || s.category.text, s.name.error || s.name.text];
                                } if (sortBy === 'category') {
                                    return [s.error, s.category.error || s.category.text, s.division.error || s.division.text, s.name.error || s.name.text];
                                }
                                return [s.error, s[sortBy].error || s[sortBy].text, s.name.error || s.name.text];
                            }, [sortAscDesc, sortAscDesc, sortAscDesc]).map((s) => (
                                <TableRow key={s.id}>
                                    {s.error ? (
                                        <TableCell padding='checkbox' style={{ textAlign: 'center' }}>
                                            <span style={{ fontSize: '18px' }} className={classes.error}><i className='fas fa-exclamation-circle' /></span>
                                        </TableCell>
                                    ) : s.importing ? (
                                        <TableCell padding='checkbox' style={{ textAlign: 'center' }}>
                                            <CircularProgress size={18} />
                                        </TableCell>
                                    ) : s.importingSuccessful ? (
                                        <TableCell padding='checkbox' style={{ textAlign: 'center' }}>
                                            <span style={{ fontSize: '18px' }} className={classes.success}><i className='fas fa-check-circle' /></span>
                                        </TableCell>
                                    ) : s.importingError ? (
                                        <TableCell padding='checkbox' style={{ textAlign: 'center' }}>
                                            <span style={{ fontSize: '18px' }} className={classes.error}><i className='fas fa-times' /></span>
                                        </TableCell>
                                    ) : (
                                        <TableCell padding='checkbox'>
                                            <Checkbox checked={s.selected} onChange={() => this.onSelectClick(s.id)} disabled={s.error} />
                                        </TableCell>
                                    )}
                                    <TableCell>
                                        {s.noChange ? t('nochange') : s.existing ? t('update') : t('new')}
                                    </TableCell>
                                    {['publicId', 'name', 'club', 'team', 'ics', 'email', 'division', 'category', 'power-factor', 'paid', 'squad'].map((field) => (
                                        <TableCell key={field}>
                                            {s[field].error
                                                ? (
                                                    <span className={classes.error}>
                                                        <i className='fas fa-exclamation-circle' />
    &nbsp;
                                                        {s[field].error}
                                                    </span>
                                                )
                                                : s[field].text}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button color='primary' variant='contained' style={{ margin: '0px 12px' }} disabled={allNotSelected || importing} onClick={this.onImport}>{t('importselected')}</Button>
                    <Button color='secondary' variant='contained' disabled={importing} onClick={() => this.setState({ shooters: null })}>{t('reset')}</Button>
                </div>
            </div>
        );
    }

    render() {
        const { t, match } = this.props;
        const {
            shooters, loading, exporting, exportCancelled,
        } = this.state;

        return (
            <div style={{ padding: '0px 24px', marginBottom: '24px' }}>
                <Typography variant='h6' headlineMapping={{ h6: 'div' }}>
                    {t('title-export')}
                </Typography>
                <FormControlLabel
                    control={(
                        <Checkbox
                            checked={exportCancelled}
                            onChange={() => this.setState({ exportCancelled: !exportCancelled })}
                        />
)}
                    label={(
                        <div>
                            {t('export-cancelled')}
                        </div>
                    )}
                />
                <Button variant='contained' color='primary' style={{ width: '100%', height: '100%' }} onClick={this.onExport} disabled={exporting}>
                    {exporting ? <CircularProgress size={24} /> : t('export')}
                </Button>
                <div style={{ paddingTop: '12px' }} />
                <hr />
                <div style={{ paddingTop: '12px' }} />
                <Typography variant='h6' headlineMapping={{ h6: 'div' }}>
                    {t('title-import')}
                </Typography>
                <Typography variant='body2' headlineMapping={{ body2: 'div' }}>
                    {t('intro-1')}
                    {' '}
                    {t('intro-2')}
                </Typography>
                <Typography variant='body2' headlineMapping={{ body2: 'div' }}>
                    <ol>
                        <li>{t('generic:shooter_id')}</li>
                        <li>{t('generic:shooter_name')}</li>
                        <li>{t('generic:shooter_club')}</li>
                        <li>
                            {t('generic:shooter_team')}
                            &nbsp;
                            (
                            {match.teams.map((s) => s.title).join(', ')}
                            )
                        </li>
                        <li>{t('generic:shooter_ics')}</li>
                        <li>{t('generic:shooter_email')}</li>
                        <li>{t('generic:shooter_region')}</li>
                        <li>
                            {t('generic:shooter_division')}
                            {' '}
                            (
                            {getAllDivisions({ matchType: match.type }).map((d, i) => (
                                <span>
                                    {i !== 0 ? ', ' : ''}
                                    {t(`generic:division-${d}`)}
                                </span>
                            ))}
                            )
                        </li>
                        <li>
                            {t('generic:shooter_category')}
                            {' '}
                            (
                            {t('registration:category-regular')}
                            ,
                            {' '}
                            {t('registration:category-junior')}
                            ,
                            {' '}
                            {t('registration:category-super-junior')}
                            ,
                            {' '}
                            {t('registration:category-senior')}
                            ,
                            {' '}
                            {t('registration:category-super-senior')}
                            ,
                            {' '}
                            {t('registration:category-lady-senior')}
                            ,
                            {' '}
                            {t('registration:category-grand-senior')}
                            ,
                            {' '}
                            {t('registration:category-lady')}
                            )
                        </li>
                        <li>
                            {t('generic:shooter_power_factor')}
                            {' '}
                            (
                            {t('registration:minor')}
                            ,
                            {' '}
                            {t('registration:major')}
                            )
                        </li>
                        <li>
                            {t('registration:paid')}
                            {' '}
                            (
                            {t('generic:yes')}
                            ,
                            {' '}
                            {t('generic:no')}
                            )
                        </li>
                        {(match.squads || []).length > 0 && (
                        <li>
                            {t('generic:squad_number')}
                            {' '}
                            (
                            <i>{t('optional')}</i>
                            ) (
                            {match.squads.map((s) => s.title).join(', ')}
                            )
                        </li>
                        )}
                    </ol>
                </Typography>
                <Typography variant='body2' headlineMapping={{ body2: 'div' }}>
                    {t('intro-3')}
                </Typography>

                {!shooters && (
                <div style={{ position: 'relative', marginTop: '12px' }}>
                    <Button variant='contained' color='primary' style={{ width: '100%', height: '100%' }} disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : t('loadfile')}
                    </Button>
                    {!loading && (
                    <input
                        style={{
                            position: 'absolute', cursor: 'pointer', top: '0px', left: '0px', width: '100%', height: '100%', opacity: 0.01,
                        }}
                        type='file'
                        onChange={this.onFile}
                        accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    />
                    )}
                </div>
                )}

                {shooters && this.renderTable()}
            </div>
        );
    }
}

const styles = {
    error: {
        color: red[500],
    },
    success: {
        color: green[500],
    },
};

ShootersListImport.propTypes = {
    setTitle: PropTypes.func.isRequired,
    setSaveButtonCallback: PropTypes.func.isRequired,
    setExtraButtons: PropTypes.func.isRequired,
    setBackButtonVisible: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
        ]),
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        squads: PropTypes.array.isRequired,
        teams: PropTypes.array.isRequired,
        registrationExtraFields: PropTypes.array,
        region: PropTypes.string,
    }).isRequired,
    auth: PropTypes.string.isRequired,
};

export default withStyles(styles)(withTranslation('shooterslistimport')(ShootersListImport));
