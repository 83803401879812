/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component, useEffect, useState } from 'react';
import _ from 'lodash';
import 'moment/min/locales.min';
import { Route, Switch, Redirect } from 'react-router-dom';
import localforage from 'localforage';
import Thinking from './home/Thinking';
import Home from './home/Home';
import HomeApp from './home/HomeApp';
import HomeAppShouldResume from './home/HomeApp.shouldresume';
import HomeSignIn from './home/Home.signin';
import HomeNew from './home/Home.new';
import Aria from './home/Aria';
import HomeScore from './home/Score';
import Map from './home/Map';
import ProfilePage from './home/ProfilePage';

import MatchHome from './matches/MatchHome';
import Registration from './matches/Registration';
import ViewerRegistration from './matches/ViewerRegistration';
import ShootersList from './matches/ShootersList';
import ShootersListImport from './matches/ShootersListImport';
import Score from './matches/Score';
import Squads from './matches/Squads';
import PrintSquadsScoreSheet from './matches/PrintSquadsScoreSheet';
import Stages from './matches/Stages';
import Stage from './matches/Stage';
import Upgrade from './matches/Upgrade';
import Staff from './matches/Staff';
import Teams from './matches/TeamsManage';
import ScoreTable from './matches/ScoreTable';
import FlagsPodium from './matches/FlagsPodium';
import ViewerResults from './matches/ViewerResults';
import CompareShooters from './matches/CompareShooters';
import MatchManageHome from './matches/ManageHome';
import AnnouncementsEditor from './matches/AnnouncementsEditor';
import Settings from './matches/Settings';
import MatchManageWrapper from './matches/ManageWrapper';
import ExportToWinMss from './matches/ExportToWinMss';
import ViewerWrapper from './matches/ViewerWrapper';
import ExtractHistory from './matches/ExtractHistory';
import Chrono from './matches/Chrono';
import SmartReports from './matches/SmartReports';
import OfflineManager from './matches/OfflineManager';
import StorageManager from './matches/StorageManager';
import ManageComments from './matches/ManageComments';
import LeagueMain from './leagues/Main';

import ClubManageWrapper from './clubs/ManageWrapper';

import RegionManageHome from './regions/ManageHome';
import RegionManageWrapper from './regions/ManageWrapper';
import RegionNewClub from './regions/NewClub';
import RegionClub from './regions/Club';
import RegionHome from './regions/Home';

import api from './Api';

let queryProps = {};

const EditSquads = (props) => <Squads manage {...props} />;
const EditRegistration = (props) => <Registration manage {...props} />;
const RoViewerRegistration = (props) => <ViewerRegistration ro {...props} />;

const $MatchManageHome = (props) => (
    <MatchManageWrapper pageId='home' {...queryProps} matchId={props.match.params.matchId} url={props.match.url} history={props.history} component={MatchManageHome} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const MatchManageRegistration = (props) => (
    <MatchManageWrapper pageId='registration' {...queryProps} matchId={props.match.params.matchId} url={props.match.url} history={props.history} component={EditRegistration} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const MatchManageShootersList = (props) => (
    <MatchManageWrapper pageId='shooterslist' {...queryProps} matchId={props.match.params.matchId} url={props.match.url} history={props.history} component={ShootersList} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const MatchManageShootersListImport = (props) => (
    <MatchManageWrapper pageId='shooterslist' {...queryProps} matchId={props.match.params.matchId} url={props.match.url} history={props.history} component={ShootersListImport} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const MatchManageStaff = (props) => (
    <MatchManageWrapper pageId='staff' {...queryProps} matchId={props.match.params.matchId} url={props.match.url} history={props.history} component={Staff} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const MatchManageTeams = (props) => (
    <MatchManageWrapper pageId='teams' {...queryProps} matchId={props.match.params.matchId} url={props.match.url} history={props.history} component={Teams} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const MatchManageStages = (props) => (
    <MatchManageWrapper pageId='stages' {...queryProps} matchId={props.match.params.matchId} url={props.match.url} history={props.history} component={Stages} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const MatchManageSquads = (props) => (
    <MatchManageWrapper pageId='squads' {...queryProps} matchId={props.match.params.matchId} url={props.match.url} history={props.history} component={EditSquads} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const MatchManageScore = (props) => (
    <MatchManageWrapper pageId='scores' {...queryProps} matchId={props.match.params.matchId} url={props.location.pathname} history={props.history} component={Score} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const MatchManageScoreTable = (props) => (
    <MatchManageWrapper pageId='scoretable' {...queryProps} matchId={props.match.params.matchId} url={props.match.url} history={props.history} component={ScoreTable} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const MatchManageUpgrade = (props) => (
    <MatchManageWrapper pageId='upgrade' {...queryProps} matchId={props.match.params.matchId} url={props.match.url} history={props.history} component={Upgrade} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const MatchManageSquadsPrint = (props) => (
    <MatchManageWrapper pageId='printsquads' {...queryProps} matchId={props.match.params.matchId} url={props.match.url} history={props.history} component={PrintSquadsScoreSheet} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const MatchManageAnnouncementsEditor = (props) => (
    <MatchManageWrapper pageId='announcements' {...queryProps} matchId={props.match.params.matchId} url={props.match.url} history={props.history} component={AnnouncementsEditor} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const MatchManageSettings = (props) => (
    <MatchManageWrapper pageId='settings' {...queryProps} matchId={props.match.params.matchId} url={props.match.url} history={props.history} component={Settings} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const MatchManageSmartReports = (props) => (
    <MatchManageWrapper pageId='stats' {...queryProps} matchId={props.match.params.matchId} url={props.match.url} history={props.history} component={SmartReports} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const MatchManageOffline = (props) => (
    <MatchManageWrapper pageId='offline' {...queryProps} matchId={props.match.params.matchId} url={props.match.url} history={props.history} component={OfflineManager} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const MatchManageStorage = (props) => (
    <MatchManageWrapper pageId='offline' {...queryProps} matchId={props.match.params.matchId} url={props.match.url} history={props.history} component={StorageManager} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const MatchManageComments = (props) => (
    <MatchManageWrapper pageId='managecomments' {...queryProps} matchId={props.match.params.matchId} url={props.match.url} history={props.history} component={ManageComments} componentProps={{ ..._.omit({ ...props, ...queryProps }, 'match'), shooterId: props.match.params.shooterId }} />
);

const ExportToWinMss$ = (props) => (
    <MatchManageWrapper pageId='winmss' {...queryProps} matchId={props.match.params.matchId} url={props.match.url} history={props.history} component={ExportToWinMss} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const ExtractHistory$ = (props) => (
    <MatchManageWrapper pageId='extracthistory' {...queryProps} mode={props.mode} matchId={props.match.params.matchId} url={props.match.url} history={props.history} component={ExtractHistory} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const Chrono$ = (props) => (
    <MatchManageWrapper pageId='chrono' {...queryProps} mode={props.mode} matchId={props.match.params.matchId} url={props.match.url} history={props.history} component={Chrono} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const ViewerStages = (props) => (
    <ViewerWrapper {...queryProps} history={props.history} matchId={props.match.params.matchId} url={props.match.url} component={Stages} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const ViewerStage = (props) => (
    <ViewerWrapper {...queryProps} history={props.history} matchId={props.match.params.matchId} url={props.match.url} component={Stage} componentProps={{ ..._.omit({ ...props, ...queryProps }, 'match'), stageIdx: parseInt(props.match.params.stageIdx, 10) }} />
);

const ViewerScoreTable = (props) => (
    <ViewerWrapper {...queryProps} history={props.history} matchId={props.match.params.matchId} url={props.match.url} component={ScoreTable} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const ViewerFlagsPodium = (props) => (
    <ViewerWrapper {...queryProps} history={props.history} matchId={props.match.params.matchId} url={props.match.url} component={FlagsPodium} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const ViewerResultsWrapper = (props) => (
    <ViewerWrapper {...queryProps} history={props.history} matchId={props.match.params.matchId} url={props.match.url} component={ViewerResults} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const ViewerShooterStats = (props) => (
    <ViewerWrapper {...queryProps} history={props.history} matchId={props.match.params.matchId} url={props.match.url} component={CompareShooters} componentProps={{ ..._.omit({ ...props, ...queryProps }, 'match'), single: true }} />
);

const ViewerCompareShooters = (props) => (
    <ViewerWrapper {...queryProps} history={props.history} matchId={props.match.params.matchId} url={props.match.url} component={CompareShooters} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const ViewerSquads = (props) => (
    <ViewerWrapper {...queryProps} history={props.history} matchId={props.match.params.matchId} url={props.match.url} component={Squads} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const ViewerShootersList = (props) => (
    <ViewerWrapper {...queryProps} history={props.history} matchId={props.match.params.matchId} url={props.match.url} component={ShootersList} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const ViewerRegistrationMain = (props) => (
    <ViewerWrapper {...queryProps} history={props.history} matchId={props.match.params.matchId} url={props.match.url} pathname={props.location.pathname} component={ViewerRegistration} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const ViewerRoRegistrationMain = (props) => (
    <ViewerWrapper {...queryProps} history={props.history} matchId={props.match.params.matchId} url={props.match.url} pathname={props.location.pathname} component={RoViewerRegistration} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const RegionManageHome$ = (props) => (
    <RegionManageWrapper pageId='home' {...queryProps} regionId={props.match.params.regionId} url={props.match.url} history={props.history} component={RegionManageHome} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const RegionHome$ = (props) => (
    <RegionHome history={props.history} regionId={props.match.params.regionId} url={props.match.url} {...queryProps} pathname={props.location.pathname} />
);

const RegionNewClub$ = (props) => (
    <RegionManageWrapper pageId='newclub' {...queryProps} regionId={props.match.params.regionId} url={props.match.url} history={props.history} component={RegionNewClub} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
);

const RegionClub$ = (props) => (
    <RegionManageWrapper pageId={`clubs/${props.match.params.clubId}`} {...queryProps} regionId={props.match.params.regionId} url={props.match.url} history={props.history} component={RegionClub} componentProps={{ ..._.omit(props, 'match'), clubId: parseInt(props.match.params.clubId, 10) }} />
);

const ClubManageHome$ = (props) => (
    <ClubManageWrapper pageId='home' {...queryProps} clubId={props.match.params.clubId} url={props.match.url} history={props.history} component={RegionClub} componentProps={{ ..._.omit(props, 'match'), clubId: props.match.params.clubId }} />
);

const LeagueMain$ = (props) => (
    <LeagueMain leagueId={props.match.params.leagueId} {...queryProps} url={props.match.url} history={props.history} />
);

class App extends Component {
    // Remove the server-side injected CSS.
    componentDidMount() {
        queryProps = _.pick(this.props, ['src', 'mode', 'onUpdateThemeLocale', 'accessToken', 'name', 'user']);

        const jssStyles = document.getElementById('jss-server-side');
        if (jssStyles && jssStyles.parentNode) {
            jssStyles.parentNode.removeChild(jssStyles);
        }
    }

    render() {
        const { ssrMatch, ssrAnnouncements } = this.props;
        queryProps = _.pick(this.props, ['src', 'mode', 'onUpdateThemeLocale', 'accessToken', 'name', 'user', 'registrationCode']);

        api.init({ isApp: queryProps.mode === 'app' });

        // Defined here since we use ssrMatch and ssrAnnouncements (and we don't want a
        // component in the way, since that would cause a re-render between pages)
        const ViewerMatchHome = (props) => (
            <ViewerWrapper {...queryProps} history={props.history} matchId={props.match.params.matchId} ssrMatch={ssrMatch} ssrAnnouncements={ssrAnnouncements} url={props.match.url} component={MatchHome} componentProps={_.omit({ ...props, ...queryProps }, 'match')} />
        );

        return (
            <div>
                <Switch>
                    {(queryProps.mode !== 'app') && <Route path='/' exact component={Aria} />}
                    {(queryProps.mode === 'app') && <Route path='/' exact component={HomeApp} />}

                    <Route path='/uuid' exact render={() => {
                        const uuid = window.localStorage.getItem('device-uuid');
                        return <div>Device UUID: {uuid} <button onClick={() => navigator.clipboard.writeText(uuid)}>Copy</button></div>;
                    }} />

                    
                    <Route path='/app' exact render={(props) => <HomeApp {...this.props} {...props} />} />
                    <Route path='/app/:screenId' render={(props) => <HomeApp {...this.props} {...props} />} />
                    <Route path='/thinking' component={Thinking} />
                    <Route path='/signin' component={HomeSignIn} />
                    <Route path='/new' exact component={HomeNew} />
                    <Route path='/new/:state' component={HomeNew} />
                    <Route path='/aria' component={Home} />
                    <Route path='/map' exact component={Map} />
                    <Route path='/app-should-resume' exact component={HomeAppShouldResume} />
                    <Route path='/clear-cache' exact component={ClearCache} />
                    <Route path='/scoring-demo/' exact component={HomeScore} />
                    <Route path='/scoring-demo/:targets' component={HomeScore} />

                    {/* Profile Pages */}
                    <Route path='/shooters/:loginId'>
                        {() => {
                            window.localStorage.removeItem('signedIn');
                            return <Redirect to='/app/1' />;
                        }}
                    </Route>

                    {/* Regions */}
                    <Route path='/regions/:regionId' exact render={RegionHome$} />
                    <Route path='/regions/:regionId/manage' exact render={RegionManageHome$} />
                    <Route path='/regions/:regionId/manage/newclub' exact render={RegionNewClub$} />
                    <Route path='/regions/:regionId/manage/clubs/:clubId' exact render={RegionClub$} />

                    {/* Clubs */}
                    <Route path='/clubs/:clubId/manage' exact render={ClubManageHome$} />

                    {/* legaues */}
                    <Route path='/leagues/:leagueId' exact render={LeagueMain$} />

                    {/* Matches */}
                    <Route path='/:matchId' exact render={ViewerMatchHome} />
                    <Route path='/:matchId/squads' exact render={ViewerSquads} />
                    <Route path='/:matchId/shooters' exact render={ViewerShootersList} />
                    <Route path='/:matchId/registration' render={ViewerRegistrationMain} />
                    <Route path='/:matchId/ro-registration' render={ViewerRoRegistrationMain} />
                    <Route path='/:matchId/stages' exact render={ViewerStages} />
                    <Route path='/:matchId/stages/:stageIdx' exact component={ViewerStage} />
                    <Route path='/:matchId/results' exact render={ViewerResultsWrapper} />
                    <Route path='/:matchId/shooter-stats' render={ViewerShooterStats} />
                    <Route path='/:matchId/compare-shooters' exact render={ViewerCompareShooters} />
                    <Route path='/:matchId/score-table' exact render={ViewerScoreTable} />
                    <Route path='/:matchId/flags-podium' exact render={ViewerFlagsPodium} />
                    <Route path='/:matchId/manage' exact render={$MatchManageHome} />
                    <Route path='/:matchId/manage/staff' exact render={MatchManageStaff} />
                    <Route path='/:matchId/manage/teams' exact render={MatchManageTeams} />
                    <Route path='/:matchId/manage/registration' exact render={MatchManageRegistration} />
                    <Route path='/:matchId/manage/shooters' render={MatchManageShootersList} />
                    <Route path='/:matchId/manage/shooters-import' exact render={MatchManageShootersListImport} />
                    <Route path='/:matchId/manage/stages' exact render={MatchManageStages} />
                    <Route path='/:matchId/manage/stages/:stageIndex' exact render={MatchManageStages} />
                    <Route path='/:matchId/manage/squads' exact render={MatchManageSquads} />
                    <Route path='/:matchId/manage/squads/print' exact render={MatchManageSquadsPrint} />
                    <Route path='/:matchId/manage/announcements' exact render={MatchManageAnnouncementsEditor} />
                    <Route path='/:matchId/manage/scores' render={MatchManageScore} />
                    <Route path='/:matchId/manage/score-table' exact render={MatchManageScoreTable} />
                    <Route path='/:matchId/manage/upgrade' exact render={MatchManageUpgrade} />
                    <Route path='/:matchId/manage/settings' exact render={MatchManageSettings} />
                    <Route path='/:matchId/manage/winmss' exact render={ExportToWinMss$} />
                    <Route path='/:matchId/manage/history' exact render={ExtractHistory$} />
                    <Route path='/:matchId/manage/chrono' render={Chrono$} />
                    <Route path='/:matchId/manage/stats' render={MatchManageSmartReports} />
                    <Route path='/:matchId/manage/offline' render={MatchManageOffline} />
                    <Route path='/:matchId/manage/storage' render={MatchManageStorage} />
                    <Route path='/:matchId/manage/comments/:shooterId' render={MatchManageComments} />
                </Switch>
            </div>
        );
    }
}

export default App;
/* eslint-enable react/destructuring-assignment */
/* eslint-enable react/prop-types */

function ClearCache() {
    const [keysDeleted, setKeysDeleted] = useState([]);
    const [localStorageCleared, setLocalStorageCleared] = useState('no');
    const [sessionStorageCleared, setSessionStorageCleared] = useState('no');

    useEffect(() => {
        (async () => {
            const keys = (await localforage.keys()).filter((k) => k.indexOf('api-cache-') === 0);

            keys.forEach((key) => {
                (async () => {
                    await localforage.removeItem(key);
                })();
            });

            setKeysDeleted(keys);

            localStorage.clear();
            setLocalStorageCleared('yes');

            sessionStorage.clear();
            setSessionStorageCleared('yes');
        })();
    }, []);

    return (
        <div>
            Cache cleared. Deleted
            {` ${keysDeleted.length} `}
            keys.
            <br />
            Local storage cleared:
            {` ${localStorageCleared} `}
            <br />
            Session storage cleared:
            {` ${sessionStorageCleared} `}
        </div>
    );
}
